import React, { useEffect, useState } from "react";
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Alert,
  Avatar,
  Box,
  Container,
  // Chip,
  // ListItemIcon,
  Stack,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CallIcon from "@mui/icons-material/Call";
import Paper from "@mui/material/Paper";
import CircleIcon from "@mui/icons-material/Circle";
import { VkAreaHeader } from "./VkAreaHeader";

/**
 *
 * @param {*} delay int refresh interval in sec
 * @returns
 */
export const VkOffer = (props) => {
  const { sqldata, ...other } = props;
  const [data, setData] = useState([]);
  const [curlst, setCurlst] = useState([]);
  // const [requery, setRequery] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fltba, setFltba] = useState("");
  const [fltcur, setFltcur] = useState("");

  // console.log("VkOffer sqldata=" + JSON.stringify(sqldata));
  useEffect(() => {
    let lst = [];
    let k = 0;
    sqldata.map((v) => {
      for (k = 0; k < lst.length && lst[k].chid !== v.chid; ++k) {}
      if (k === lst.length) {
        lst.push({
          chid: v.chid,
          name: v.name,
          so: Number(v.sortorder),
        });
      }
    });
    setCurlst(
      lst.sort((a, b) => {
        return a.so - b.so;
      })
    );
    setData(sqldata);
    setError(null);
  }, [sqldata]);

  const handleChangeBA = (event) => {
    setFltba(event.target.value);
  };
  const handleChangeCur = (event) => {
    setFltcur(event.target.value);
  };

  function FltBA() {
    return (
      <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
        <InputLabel id="labelBA">Операції</InputLabel>
        <Select
          labelId="labelBA"
          id="ba"
          value={fltba}
          label="Операції"
          onChange={handleChangeBA}
        >
          <MenuItem value="" divider={true}>
            <b>Куп + Прод</b>
          </MenuItem>
          <MenuItem value="bid">
            {/* <ListItemIcon>
              <UploadIcon fontSize="small" color="success" />
            </ListItemIcon> */}
            <Typography variant="inherit">Куплю</Typography>
          </MenuItem>
          <MenuItem value="ask">
            {/* <ListItemIcon>
              <UploadIcon fontSize="small" color="info" />
            </ListItemIcon> */}
            <Typography variant="inherit">Продам</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  function FltCur() {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="labelCur">Валюти</InputLabel>
        <Select
          labelId="labelCur"
          id="cur"
          value={fltcur}
          label="Всі валюти"
          onChange={handleChangeCur}
        >
          <MenuItem key="" value="" divider={true}>
            <b>Всі валюти</b>
          </MenuItem>
          {curlst.map((v) => {
            return (
              <MenuItem key={v.chid} value={v.chid}>
                {v.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  const Offer = (props) => {
    const { v } = props;
    return (
      <Paper elevation={3} sx={{ padding: 1, minWidth: 300 }}>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {v.bidask === "bid" ? (
            <CircleIcon fontSize="small" color="success" />
          ) : (
            <CircleIcon fontSize="small" color="info" />
          )}
          <Typography>{v.bidask === "bid" ? "куплю" : "продам"}</Typography>
          <Avatar
            alt={v.chid}
            src={`./flag/${v.curid}.svg`}
            sx={{
              width: 24,
              height: 24,
              border: "solid lightgrey 1px",
            }}
          />
          <Typography>{v.chid}</Typography>
          <Typography variant="button" fontSize={"125%"}>
            {Number(v.price).toPrecision(4)}
          </Typography>
          <Typography variant="caption">{hd(v.tm)}</Typography>
        </Stack>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>{v.name}</Typography>
          <Stack direction={"row"} gap={0.5}>
            <CallIcon fontSize="small" />
            {v.tel}
          </Stack>
        </Stack>
        <Typography>{`від ${
          Math.abs(v.amnt) < 1500 ? "500" : "1 000"
        } до ${Math.abs(v.amnt).toLocaleString("uk-UA")}`}</Typography>
        {v.onote !== undefined && v.onote !== "" && (
          <Box
            bgcolor={"whitesmoke"}
            color={"whitesmoke.contrastText"}
            p={"2px"}
          >
            <Typography>{v.onote}</Typography>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    data.length !== 0 && (
      <Box {...other}>
        <Stack gap={1} width="100%">
          <VkAreaHeader text="Оголошення" />
          <Stack direction={"row"} gap={2} justifyContent={"center"}>
            <FltBA />
            <FltCur />
          </Stack>
          <Container align="center">
            <Stack
              direction="row"
              useFlexGap
              flexWrap="wrap"
              gap={1}
              alignItems={"center"}
            >
              {data.map((v) => {
                return (
                  (fltba === "" || fltba === v.bidask) &&
                  (fltcur === "" || fltcur === v.chid) && (
                    <Offer id={"ppid_" + v.oid} key={"ppkey_" + v.oid} v={v} />
                  )
                );
              })}
            </Stack>
          </Container>
          {error && (
            <Alert severity="error">
              <Typography> {`${error}`}</Typography>
            </Alert>
          )}
          <Alert
            icon={false}
            severity="warning"
            sx={{ justifyContent: "center" }}
          >
            <Typography variant="caption">
              Сайт не несе відповідальності за зміст оголошень.
            </Typography>
          </Alert>
        </Stack>
      </Box>
    )
  );
};

//  humanDate
function hd(vdate) {
  if (vdate === undefined || vdate === "") {
    return "";
  }
  let vnd = new Date();
  let vcd = new Date(vdate);
  // !!! FOR TESTING
  // return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  if (vnd.toISOString().substring(0, 10) === vdate.substring(0, 10)) {
    return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  }
  return vcd.toISOString().substring(0, 10);
}

{
  /*<div id={"tid_" + v.oid} key={"tkey_" + v.oid}>
                  <Accordion id={"aid_" + v.oid} key={"akey_" + v.oid}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id={"accordSum_" + v.oid}
    ba={v.bidask}
    cur={v.chid}
  >
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {v.bidask === "bid" ? (
        <CircleIcon fontSize="small" color="success" />
      ) : (
        <CircleIcon fontSize="small" color="info" />
      )}{" "}
      <Typography>
        {v.bidask === "bid" ? "куплю" : "продам"}
      </Typography>
      <Avatar
        alt={v.chid}
        src={`./flag/${v.curid}.svg`}
        sx={{
          width: 24,
          height: 24,
          border: "solid lightgrey 1px",
        }}
      />
      <Typography>{v.chid}</Typography>
      <Typography variant="button" fontSize={"125%"}>
        {Number(v.price).toPrecision(4)}
      </Typography>
      <Typography variant="caption">{hd(v.tm)}</Typography>
    </Stack>
  </AccordionSummary>
  <AccordionDetails>
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography>{v.name}</Typography>
      <Stack direction={"row"} gap={0.5}>
        <CallIcon fontSize="small" />
        {v.tel}
      </Stack>
    </Stack>
    <Typography>{`від ${
      Math.abs(v.amnt) < 1500 ? "500" : "1 000"
    } до ${Math.abs(v.amnt).toLocaleString(
      "uk-UA"
    )}`}</Typography>
    {v.onote !== undefined && v.onote !== "" && (
      <Box
        bgcolor={"whitesmoke"}
        color={"whitesmoke.contrastText"}
        p={"2px"}
      >
        <Typography>{v.onote}</Typography>
      </Box>
    )}
  </AccordionDetails>
</Accordion> 
</div>*/
}
