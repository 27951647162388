import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import { Container, Stack } from "@mui/material";
import Badge from "@mui/material/Badge";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Box from "@mui/material/Box";

import { VkArchive } from "./components/VkArchive";
import { VkFooter } from "./components/VkFooter";
import { VkHeader } from "./components/VkHeader";
import { VkRate } from "./components/VkRate";
import { VkOffer } from "./components/VkOffer";
import { getFetch } from "./driver";
import { subscribe, unsubscribe } from "./events";

function App(props) {
  // document.title = "КанторФК";
  const [page, setPage] = React.useState(0); // BottomNavigation

  // const [route, setRoute] = useState("rate");
  const [rates, setRates] = useState([]);
  const [offers, setOffers] = useState([]);
  const [archRates, setArchRates] = useState([]);
  const [arch, setArch] = useState({
    period: new Date(),
    cur: "840",
  });
  const [error, setError] = useState(null);
  const [loadingRate, setLoadingRate] = useState(true);
  const [loadingOffer, setLoadingOffer] = useState(true);
  // const [offerCount, setOfferCount] = useState(0);
  const prevOfferCount = useRef(0);

  const delayRate = 200; // 200sec
  const delayOffer = 210; // 200sec
  const dfltKnt = "CITY";

  const loadRate = async () => {
    // console.log(`#8y3 App/loadRate started`);
    getFetch("/rates", `shop=${dfltKnt}&reqid=ratebulk`)
      .then((resp) => resp.json())
      .then((jresp) => {
        setRates(jresp.rslt);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoadingRate(false);
      });
  };

  const loadOffer = async () => {
    // console.log(`#12u App/loadOffer started`);
    getFetch("/offers", "reqid=sel")
      .then((resp) => resp.json())
      .then((jresp) => {
        // prevOfferCount.current = offers.length;
        // setOfferCount(jresp.rslt)
        setOffers(jresp.rslt);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoadingOffer(false);
      });
  };

  useEffect(() => {
    // const loadArchive = async (v) => {
    // console.log(`#12u App/loadArchive started`);
    // console.log("#8wuk App.js getFetch=" + JSON.stringify(arch));
    // setArch(v);
    getFetch(
      "/archive",
      `reqid=ratesAvrg&period=${arch.period
        .toISOString()
        .substring(0, 7)}&cur=${arch.cur}`
    )
      .then((resp) => resp.json())
      .then((jresp) => {
        setArchRates(jresp.rslt);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoadingOffer(false);
      });
    // };
  }, [arch]);

  useEffect(() => {
    // console.log(`#34hn useEffect started`);
    // return;
    loadRate();
    loadOffer();
    const tmrRate = setInterval(loadRate, 1000 * delayRate); //
    const tmrOffer = setInterval(loadOffer, 1000 * delayOffer); //
    // const tmr = setInterval(load, 5000); // for testing
    return () => {
      clearInterval(tmrRate);
      clearInterval(tmrOffer);
    };
  }, [delayRate, delayOffer, dfltKnt]);

  useEffect(() => {
    subscribe("handle_archRate", (v) => {
      console.log("#26h App.js subscribe=" + JSON.stringify(v.detail));
      setArch(v.detail);
    });
    return () => {
      unsubscribe("handle_archRate", () => {});
    };
  });

  const menu_onChange = (event, newValue) => {
    setPage(newValue);
    if (newValue == 1) {
      prevOfferCount.current = offers.length;
    }
  };

  return (
    <Stack gap={1}>
      <VkHeader />
      <Container maxWidth="xl" align="center">
        {/* <Box sx={{ width: "100%" }}> */}
        <Box width="100%" maxWidth={"sm"}>
          <BottomNavigation showLabels value={page} onChange={menu_onChange}>
            <BottomNavigationAction label="Курси" icon={<PriceChangeIcon />} />
            <BottomNavigationAction
              label="Заявки"
              icon={
                <Badge
                  badgeContent={offers.length}
                  color={
                    prevOfferCount.current == offers.length ? "info" : "warning"
                  }
                >
                  <FavoriteIcon />
                </Badge>
              }
              // disabled={offers}
            />
            <BottomNavigationAction label="Архів" icon={<ArchiveIcon />} />
          </BottomNavigation>
        </Box>

        {page === 0 && <VkRate sqldata={rates} maxWidth={"480px"} />}
        {page === 1 && <VkOffer sqldata={offers} />}
        {page === 2 && <VkArchive sqldata={archRates} dflt={arch} />}
      </Container>
      <VkFooter />
    </Stack>
  );
}

export default App;
