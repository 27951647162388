import React, { useEffect, useState, useRef } from "react";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LineChart } from "@mui/x-charts/LineChart";

import { publish } from "../events";

export const VkArchive = (props) => {
  const { sqldata, dflt, ...other } = props;
  const [data, setData] = useState([]);
  const [fltcur, setFltcur] = useState(dflt.cur); // currency filter
  const [fltprd, setFltprd] = useState(dflt.period); // period filter
  const [lch, setLch] = useState([]); // line chart

  useEffect(() => {
    if (sqldata.length == 0) {
      return;
    }
    // console.log("#753u VkArchive/useEffect sqldata=" + JSON.stringify(sqldata));
    let arate = [];
    for (let i = 0; i < sqldata.length; ++i) {
      arate.push({
        period: sqldata[i].period.slice(-2),
        bid: Number(sqldata[i].beq) / Number(sqldata[i].bamnt), //.toFixed(2)
        ask: Number(sqldata[i].aeq) / Number(sqldata[i].aamnt), //.toFixed(2)
      });
    }
    // console.log("#29h VkArchive/useEffect sqldata=" + JSON.stringify(arate));
    setLch(arate);
    setData(arate.reverse());
  }, [sqldata]);

  const period_onChange = (v) => {
    // console.log(`#83jm VkArchive/period_onChange newDate=${v}`);
    let dd = new Date(v);
    setFltprd(dd);
    publish("handle_archRate", {
      period: dd,
      cur: fltcur,
    });
  };

  const cur_onChange = (e) => {
    // console.log(`#47j VkArchive/period_onChange cur=${e.target.value}`);
    setFltcur(e.target.value);
    publish("handle_archRate", {
      period: fltprd,
      cur: e.target.value,
    });
  };

  const Row = (props) => {
    const { itm } = props;

    return (
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center">
          <Typography>{itm.period}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{itm.bid.toFixed(2)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{itm.ask.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Stack direction={"row"} gap={2} justifyContent={"center"}>
          <LocalizationProvider dateAdapter={AdapterDayjs} padding="none">
            {/* <DemoContainer components={["DatePicker"]}> */}
            <DatePicker
              label="Період"
              views={["month", "year"]}
              slotProps={{ textField: { size: "small" } }}
              defaultValue={dayjs(fltprd)}
              // value={dayjs(period)}
              onChange={period_onChange}
              format="YYYY-MM"
              // minDate="01-01-2024"
              closeOnSelect={true}
              disableFuture={true}
              sx={{ maxWidth: "140px" }}
            />
            {/* </DemoContainer> */}
          </LocalizationProvider>
          <FormControl size="small">
            <InputLabel id="labelCur">Валюта</InputLabel>
            <Select
              //   labelId="labelCur"
              id="cur"
              value={fltcur}
              //   defaultValue={fltcur}
              label="Валюта"
              // size="small"
              onChange={cur_onChange}
            >
              {[
                { id: "840", chid: "USD" },
                { id: "978", chid: "EUR" },
                { id: "985", chid: "PLN" },
              ].map((v) => {
                return (
                  <MenuItem key={v.id} value={v.id}>
                    {v.chid}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <LineChart
          //   width="100%"
          height={250}
          series={[
            {
              dataKey: "bid",
              label: "куп",
              showMark: false, //({ index }) => index % 4 === 0,
            },
            {
              dataKey: "ask",
              label: "прод",
              showMark: false, //({ index }) => index % 4 === 0,
            },
          ]}
          xAxis={[{ dataKey: "period", max: 31 }]}
          dataset={lch}
        />
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography>ДЕНЬ</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>КУПІВЛЯ</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>ПРОДАЖ</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((v) => {
                return <Row id={v.period} key={v.period} itm={v} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Alert
          icon={false}
          severity="warning"
          sx={{ justifyContent: "center" }}
        >
          <Typography variant="caption">
            Вказано середній курс операцій за період
          </Typography>
        </Alert>
      </Container>
    </React.Fragment>
  );
};
